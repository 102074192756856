// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width:601px){.styles__gridFieldset___c_BNT{display:flex;flex-wrap:wrap;justify-content:space-between}.styles__gridFieldset___c_BNT>div{max-width:calc(50% - 5px)}}.styles__textFieldRoot___CGSP4{margin:auto 0!important;padding:1em 0!important}.styles__textFieldInputRoot___ohrw9{margin-top:0!important}`, "",{"version":3,"sources":["webpack://./src/components/CreateGroupContents/styles.scss"],"names":[],"mappings":"AAEI,oCAFJ,8BAKQ,YAAa,CACb,cAAe,CACf,6BAA8B,CAPtC,kCAUY,yBAA0B,CAC7B,CAIT,+BACI,uBAAwB,CACxB,uBAAwB,CAC3B,oCAEG,sBAAyB","sourcesContent":[".gridFieldset {\n\n    @media screen and (min-width: 601px) {\n\n        // Currently, supporting certain mobile releases on Cordova limit us from using CSS Grid\n        display: flex;\n        flex-wrap: wrap;\n        justify-content: space-between;\n\n        >div {\n            max-width: calc(50% - 5px);\n        }\n    }\n}\n\n.textFieldRoot{\n    margin: auto 0!important;\n    padding: 1em 0!important;\n}\n.textFieldInputRoot{\n    margin-top: 0px!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridFieldset": `styles__gridFieldset___c_BNT`,
	"textFieldRoot": `styles__textFieldRoot___CGSP4`,
	"textFieldInputRoot": `styles__textFieldInputRoot___ohrw9`
};
export default ___CSS_LOADER_EXPORT___;
