// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__wrapper___NO7Ps{width:100%}.styles__innerWrapper___E0Rdn{display:flex;flex-flow:column nowrap;padding:1.5em;width:100%}.styles__innerWrapper___E0Rdn>div{width:100%!important}.styles__fullWidth___gh9mV{width:100%}`, "",{"version":3,"sources":["webpack://./src/routes/app/classes/components/EditGroupPage/styles.scss"],"names":[],"mappings":"AAEA,yBACE,UAAW,CACZ,8BAKC,YAAa,CACb,uBAAW,CAFX,aAAc,CADd,UAGwB,CAJ1B,kCAOI,oBAAsB,CACvB,2BAID,UAAW","sourcesContent":["@import \"../../../../../styles/colors.json\";\n\n.wrapper {\n  width: 100%;\n}\n\n.innerWrapper {\n  width: 100%;\n  padding: 1.5em;\n  display: flex;\n  flex-flow: column nowrap;\n\n  & > div {\n    width: 100% !important;\n  }\n}\n\n.fullWidth {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles__wrapper___NO7Ps`,
	"innerWrapper": `styles__innerWrapper___E0Rdn`,
	"fullWidth": `styles__fullWidth___gh9mV`
};
export default ___CSS_LOADER_EXPORT___;
