const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Classes } = require('./styles.scss');
const UniqueId = require('lodash/uniqueId');
const GooglePhone = require('google-libphonenumber');

const PhoneUtil = GooglePhone.PhoneNumberUtil.getInstance();
require('react-phone-input-2/lib/material.css');
const { default: IntlTelInput } = require('react-phone-input-2');
const IsEmail = require('utils/is-email');

const { default: Box } = require('@mui/material/Box');
const { default: TextField } = require('@mui/material/TextField');
const { default: Divider } = require('@mui/material/Divider');
const { default: Typography } = require('@mui/material/Typography');
const { default: InputLabel } = require('@mui/material/InputLabel');
const { default: FormControl } = require('@mui/material/FormControl');
const CheckObjectsEquality = require('utils/checkObjectsEquality');
const PrefsDomain = require('domain/preferences');
const SelectField = require('components/SelectField');
const FormHelperText = require('components/FormHelperText');
const PreventNavigationDialog = require('components/PreventNavigationDialog');
const QuestionSwitch = require('../QuestionSwitch');

const internals = {};

const StyledTextField = Styled(TextField)`
    margin-top: 14px;
`;

module.exports = class UserPreferences extends React.PureComponent {

    static propTypes = {
        history: T.object,
        onSubmit: T.func,
        onSubmitNoRedirect: T.func,
        onInvalidSubmit: T.func,
        onChange: T.func,
        preferences: T.object,
        preferencesSubmiting: T.bool,
        isSignupPage: T.bool,
        isEditPage: T.bool,
        hideJobAlerts: T.bool,
        headersToShow: T.arrayOf(T.oneOf([
            'communications',
            'connections',
            'groups',
            'emails'
        ])),
        fieldsToShow: T.arrayOf(T.oneOf([
            'profileEmail',
            'phone',
            'sms',
            'connectionsVisibility',
            'groupVisibility',
            'groupMsgNotifications',
            'digestInterval',
            'pendingConnections',
            'careerAlerts',
            'messageNotifications'
        ])),
        useWrapper: T.bool
    }

    static defaultProps = {
        headersToShow: [
            'communications',
            'connections',
            'groups',
            'emails',
            'other'
        ],
        fieldsToShow: [
            'profileEmail',
            'connectionsVisibility',
            'groupVisibility',
            'groupMsgNotifications',
            'digestInterval',
            'pendingConnections',
            'careerAlerts',
            'messageNotifications'
        ],
        useWrapper: true
    }

    constructor(props) {

        super(props);

        this.id = UniqueId('user-preferences-');

        this.fields = PrefsDomain.prefFields.concat(PrefsDomain.profileFields);


        if (!this.props.isSignupPage && !this.props.isEditPage) {
            this.state = this.getStateDefaultsWithPrefs(props.preferences);
        }
        else {
            this.state = this.getStateDefaultsWithPrefsForSignUp(props.preferences);
        }

        this.submit = this._submit.bind(this);
        this.submitNoRedirect = this._submitNoRedirect.bind(this);
        this.getUserPreferences = this._getUserPreferences.bind(this);
        this.validate = this._validate.bind(this);
        this.error = this._error.bind(this);
        this.setFieldValue = this._setFieldValue.bind(this);
        this.setPhoneFieldValue = this._setPhoneFieldValue.bind(this);
        this.hasError = this._hasError.bind(this);
        this.getDescribedById = this._getDescribedById.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (this.state.isSubmitting) {
            return;
        }

        if (!this.props.isSignupPage && !this.props.isEditPage) {

            if (CheckObjectsEquality(nextProps.preferences, this.props.preferences) && !this.state.pageIsDirty && !this.props.preferencesSubmiting) {
                this.setState(this.getStateDefaultsWithPrefs(nextProps.preferences));
            }

            return;
        }

        if (CheckObjectsEquality(nextProps.preferences, this.props.preferences) && !this.state.pageIsDirty) {
            this.setState(this.getStateDefaultsWithPrefsForSignUp(nextProps.preferences));
        }
    }

    getStateDefaultsWithPrefsForSignUp(prefs) {

        const {
            phone,
            phoneCountry,
            sms,
            groupVisibility,
            connectionsVisibility,
            digestInterval,
            pendingConnections,
            careerAlerts,
            profileEmail,
            groupMsgNotifications,
            hideInterestPopup,
            messageNotifications
        } = prefs || {};

        return {
            phone: phone || '',
            phoneCountry: phoneCountry || 'us',
            sms: sms === 'on' || sms === true,
            profileEmail: profileEmail || '',
            connectionsVisibility: connectionsVisibility || 'anyone',
            groupVisibility: groupVisibility || 'anyone',
            digestInterval: digestInterval || 'daily',
            pendingConnections: pendingConnections || 'on',
            careerAlerts: this.props.hideJobAlerts ? 'off' : (careerAlerts || 'on'),
            groupMsgNotifications: groupMsgNotifications || 'on',
            hideInterestPopup: hideInterestPopup || 'false',
            pageIsDirty: false,
            pageHasErrors: false,
            phoneExist: false,
            messageNotifications: messageNotifications || 'on'
        };
    }

    getStateDefaultsWithPrefs(prefs) {

        const {
            phone,
            phoneCountry,
            sms,
            connectionsVisibility,
            groupVisibility,
            digestInterval,
            pendingConnections,
            careerAlerts,
            profileEmail,
            groupMsgNotifications,
            hideInterestPopup,
            messageNotifications
        } = prefs || {};

        return {
            phone: phone || '',
            phoneCountry: phoneCountry || 'us',
            sms: sms === 'on' || sms === true,
            profileEmail: profileEmail || '',
            connectionsVisibility: connectionsVisibility || 'anyone',
            groupVisibility: groupVisibility || '',
            digestInterval: digestInterval || '',
            pendingConnections: pendingConnections || 'on',
            careerAlerts: this.props.hideJobAlerts ? 'off' : (careerAlerts || 'on'),
            groupMsgNotifications: groupMsgNotifications || '',
            hideInterestPopup: hideInterestPopup || 'false',
            pageIsDirty: false,
            phoneExist: false,
            pageHasErrors: false,
            messageNotifications: messageNotifications || 'on'
        };
    }

    _validate(field) {

        const value = this.state[field];
        const { isSignupPage, isEditPage } = this.props;

        if (field === 'profileEmail') {
            if (value && !IsEmail(value)) {
                return 'Invalid email address';
            }
        }
        else if (field === 'phone') {

            if (value && (isEditPage || isSignupPage)) {

                const number = PhoneUtil.parse(value, this.state.phoneCountry);

                const isValid = PhoneUtil.isValidNumber(number);

                if (!isValid) {
                    return 'Invalid phone number. Must be 10-15 digits long.';
                }
            }
        }

        return null;
    }

    async _submit(cb) {

        const hasErrors = this.fields.some(this.validate);

        if (hasErrors) {

            // On submit attempt, all errors are fair game to display

            this.setState(this.fields.reduce((collector, field) => {

                collector[`${field}ErrorShow`] = false;
                if (this.validate(field)) {
                    collector[`${field}ErrorShow`] = true;
                }

                return collector;
            }, {}));

            return this.props.onInvalidSubmit();
        }

        // No errors?  Submit the field/values

        const userPreferences = this.getUserPreferences();

        this.setState({
            isSubmitting: true,
            pageIsDirty: false,
            pageHasErrors: false
        });

        // TODO consolidate this with _submitNoRedirect. This submit call is the only
        // difference between the 2.

        try {
            await this.props.onSubmit(userPreferences, cb || (() => null));

            this.setState({
                isSubmitting: false,
                pageIsDirty: false,
                pageHasErrors: false,
                phoneExist: false
            });
        }
        catch (err) {

            let phoneErr = false;
            if (err && err.message.includes('Update profile failed')) {
                phoneErr = true;
            }

            return this.setState({
                isSubmitting: false,
                pageIsDirty: true,
                pageHasErrors: true,
                phoneExist: phoneErr
            });
        }
    }

    _submitNoRedirect(cb) {

        const hasErrors = this.fields.some(this.validate);

        if (hasErrors) {

            // On submit attempt, all errors are fair game to display

            this.setState(this.fields.reduce((collector, field) => {

                collector[`${field}ErrorShow`] = false;
                if (this.validate(field)) {
                    collector[`${field}ErrorShow`] = true;
                }

                return collector;
            }, {}));

            return this.props.onInvalidSubmit();
        }

        // No errors?  Submit the field/values

        const userPreferences = this.getUserPreferences();

        this.setState({ pageIsDirty: false, pageHasErrors: false });

        // TODO consolidate this with _submit. This onSubmitNoRedirect call is the only
        // difference between the 2.
        return this.props.onSubmitNoRedirect(userPreferences, cb || (() => null)).then((response) => {

            this.setState({
                pageIsDirty: false,
                pageHasErrors: false,
                phoneExist: false
            });
        }).catch((err) => {

            let phoneErr = false;
            if (err && err.message.includes('Update profile failed')) {
                phoneErr = true;
            }

            return this.setState({
                pageIsDirty: true,
                pageHasErrors: true,
                phoneExist: phoneErr
            });
        });
    }

    _getUserPreferences() {

        return this.fields.reduce((collector, field) => {

            // From UserInputs ----
            let fieldValue = this.state[field];

            if (field === 'profileEmail') {
                fieldValue = fieldValue.replace(/\s/g, '');
            }
            // ----

            collector[field] = fieldValue;

            return collector;
        }, {});
    }

    showError(field, show) {

        show = (typeof show === 'undefined') ? this.hasError(field) : show;

        return () => {

            return this.setState({ [`${field}ErrorShow`]: show });
        };
    }

    showErrorPhone(show) {

        show = (typeof show === 'undefined') ? this.hasError('phone') : show;

        return this.setState({ phoneErrorShow: show });
    }

    _error(field) {

        if (!this.state[`${field}ErrorShow`]) {
            return null;
        }

        const error = this.validate(field);

        if (error !== null) {
            this.setState({ pageHasErrors: true });
            return <div id={this.id + '-' + field + '-error-message'} role='alert' aria-live='assertive'>{error}</div>;
        }

        return null;
    }

    _hasError(field) {

        if (this.validate(field) !== null) {
            this.setState({ pageHasErrors: true });
            return true;
        }

        return false;
    }

    _getDescribedById(field) {

        if (this.hasError(field)) {
            return (this.id + '-' + field + '-error-message');
        }

        return null;
    }

    checkPhoneNumber(phoneNumber, countryCode) {

        try {
            const number = PhoneUtil.parse(phoneNumber, countryCode);

            return PhoneUtil.isValidNumber(number) && !this.state.phoneExist;
        }
        catch (e) {

            return false;
        }
    }

    setFormField(values) {

        const newState = {
            ...this.state,
            ...values
        };

        if (!Object.prototype.hasOwnProperty.call(values, 'sms')) {

            const useSms = !!(values.phone && this.checkPhoneNumber(values.phone, values.phoneCountry));
            const enforceRules = (state) => ({
                ...state,
                sms: !!useSms
            });

            const newStateEnforced = enforceRules({
                ...this.state,
                ...values
            });

            Object.assign(newState, { ...newStateEnforced });
        }

        this.setState(newState, () => {

            if (typeof this.props.onChange === 'function') {
                Object.keys(values).forEach((key) => {

                    if (key === 'phone' || key === 'phoneCountry') {

                        const rawPhone = newState.phone;

                        this.props.onChange(
                            ['phone', 'phoneCountry', 'sms'],
                            [rawPhone, newState.phoneCountry, newState.sms]
                        );
                    }
                    else {
                        this.props.onChange(key, newState[key]);
                    }
                });
            }
        });
    }

    _setFieldValue(field, isPassedValue) {

        return (ev, checked) => {

            this.setFormField({
                [field]: isPassedValue ? checked : ev.target.value,
                pageIsDirty: true
            });
        };
    }

    setSelectValue = (field) => {

        return (evt) => {

            this.setFormField({
                [field]: evt.target.value,
                pageIsDirty: true
            });
        };
    }

    _setPhoneFieldValue(value, { countryCode, dialCode }) {

        this.setFormField({
            phone: (value ? value : ''),
            phoneCountry: countryCode,
            dialCode,
            pageIsDirty: true,
            phoneExist: false
        });
    }

    render() {

        // eslint-disable-next-line react/prop-types
        const {
            history,
            headersToShow,
            fieldsToShow,
            useWrapper,
            isSignupPage,
            isEditPage,
            hideJobAlerts
        } = this.props;

        const {
            phone,
            phoneCountry,
            sms,
            groupVisibility,
            connectionsVisibility,
            digestInterval,
            pendingConnections,
            careerAlerts,
            profileEmail,
            groupMsgNotifications,
            messageNotifications
        } = this.state;

        const noDivider = headersToShow?.length === 1;

        return (
            <div className={useWrapper ? Classes.wrapper : ''}>
                <PreventNavigationDialog
                    // When should shouldBlockNavigation be invoked,
                    // simply passing a boolean
                    // (same as 'when' prop of Prompt of React-Router)
                    saveFunction={this.submitNoRedirect}
                    pageHasErrors={this.state.pageHasErrors}
                    alertMsg={'You have made changes.\n Do you want to save or discard them?'}
                    when={this.state.pageIsDirty && !isSignupPage && !isEditPage}
                    // Navigate function
                    // eslint-disable-next-line react/prop-types
                    navigate={(path, state) => history.push(path, state)}
                    // Use as 'message' prop of Prompt of React-Router
                    shouldBlockNavigation={() => {

                        if (this.state.pageIsDirty && !isSignupPage && !isEditPage) {
                            return true;
                        }

                        return false;
                    }}
                />
                {headersToShow.includes('communications') ? <h3 className={Classes.communicationHeader}>Communication</h3> : null}
                {fieldsToShow.includes('profileEmail') || fieldsToShow.includes('phone') || fieldsToShow.includes('sms') ? <section>
                    {fieldsToShow.includes('profileEmail') ? <StyledTextField
                        type='email'
                        variant='outlined'
                        label='Backup Email'
                        fullWidth
                        inputProps={{ 'aria-label': 'Backup email input' }}
                        error={this.state.profileEmailErrorShow}
                        value={profileEmail}
                        aria-invalid={this.hasError('profileEmail')}
                        aria-describedby={this.getDescribedById('profileEmail')}
                        helperText={this.error('profileEmail')}
                        onChange={this.setFieldValue('profileEmail')}
                        onBlur={this.showError('profileEmail')}
                    /> : null}
                    {fieldsToShow.includes('phone') ?
                        <>
                            {noDivider ? null : <Divider className={Classes.divider} />}
                            <Box mt={2}>
                                <InputLabel error={this.state.phoneErrorShow} id='phone-number-input-label'>Cell Number (for text alerts)</InputLabel>
                                <IntlTelInput
                                    value={phone}
                                    containerStyle={{
                                        marginTop: '0.5rem'
                                    }}
                                    inputProps={{
                                        'aria-label': `Cell Number for text alerts input`,
                                        'aria-labelledby': 'phone-number-input-label',
                                        style: { width: '100%', maxWidth: 300 }
                                    }}
                                    onChange={this.setPhoneFieldValue}
                                    country={phoneCountry}
                                    preferredCountries={['us']}
                                    placeholder={'(402) 123-4567'}
                                    isValid={(inputNumber, country, countries) => {

                                        // eslint-disable-next-line no-extra-boolean-cast
                                        if (!!inputNumber) {

                                            const isValid = this.checkPhoneNumber(inputNumber, country.iso2);

                                            this.showErrorPhone(!isValid);

                                            return isValid;
                                        }

                                        this.showErrorPhone(false);
                                        return true;
                                    }}
                                    disableCountryCode
                                    disableCountryGuess
                                    regions={['north-america']}
                                />{this.state.phoneErrorShow ? <Typography color={'error'} >{this.state.phoneExist ? 'This phone number is already in use. Please use a different one.' : 'Enter correct phone number format'}</Typography> : null}
                            </Box>
                        </> : null}
                    {fieldsToShow.includes('sms') ? <QuestionSwitch
                        questionLabel={`Turn on text alerts so you don't miss a message?`}
                        uncheckedLabel={'No'}
                        checkedLabel={'Yes'}
                        checked={!!sms}
                        onChange={this.setFieldValue('sms', true)}
                        switchId={'sms-toggle'}
                        disabled={!phone || phone === '1'}
                    /> : null}
                </section> : null}
                {headersToShow.includes('connections') ? <>
                    {noDivider ? null : <Divider className={Classes.divider} />}
                    <h3>Connections</h3>
                </> : null}
                {fieldsToShow.includes('connectionsVisibility') ? <section>
                    <FormControl variant='outlined' fullWidth>
                        <FormHelperText id='connectionsVisibilityLabel'>Who should be able to see your connections?</FormHelperText>
                        <SelectField
                            value={connectionsVisibility}
                            labelId='connectionsVisibilityLabel'
                            onChange={this.setSelectValue('connectionsVisibility')}
                            options={[
                                { value: 'anyone', label: 'Anyone' },
                                { value: 'peers', label: 'Connected peers' },
                                { value: 'me', label: 'Only me' }
                            ]}
                        />
                    </FormControl>
                </section> : null}

                {fieldsToShow.includes('messageNotifications') ? <section>
                    <FormControl variant='outlined' fullWidth>
                        <FormHelperText id='messageNotificationsLabel'>Would you like to receive direct message notifications?</FormHelperText>
                        <SelectField
                            value={messageNotifications}
                            labelId='messageNotificationsLabel'
                            onChange={this.setSelectValue('messageNotifications')}
                            options={[
                                { value: 'on', label: 'Yes' },
                                { value: 'off', label: 'No' }
                            ]}
                            error={this.state.messageNotificationsErrorShow}
                            helperText={this.error('messageNotifications')}
                            aria-describedby={this.getDescribedById('messageNotifications')}
                        />
                    </FormControl>
                </section> : null}
                {headersToShow.includes('groups') ? <>
                    {noDivider ? null : <Divider className={Classes.divider} />}
                    <h3>Groups</h3>
                </> : null}
                {fieldsToShow.includes('groupVisibility') ? <section>
                    <FormHelperText id='groupVisibilityLabel'>Who should be able to see your groups?</FormHelperText>
                    <SelectField
                        value={groupVisibility}
                        labelId='groupVisibilityLabel'
                        onChange={this.setSelectValue('groupVisibility')}
                        options={[
                            { value: 'anyone', label: 'Anyone' },
                            { value: 'peers', label: 'Connected peers' },
                            { value: 'me', label: 'Only me' }
                        ]}
                    />
                </section> : null}
                {fieldsToShow.includes('groupMsgNotifications') ? <section>
                    <FormHelperText id='groupMsgNotificationsLabel'>Would you like to receive group chat notifications?</FormHelperText>
                    <SelectField
                        value={groupMsgNotifications}
                        labelId='groupMsgNotificationsLabel'
                        onChange={this.setSelectValue('groupMsgNotifications')}
                        options={[
                            { value: 'on', label: 'Yes' },
                            { value: 'off', label: 'No' }
                        ]}
                    />
                </section> : null}
                {headersToShow.includes('emails') ? <>
                    {noDivider ? null : <Divider className={Classes.divider} />}
                    <h3>Emails</h3>
                </> : null}
                {fieldsToShow.includes('digestInterval') ? <section>
                    <FormHelperText id='digestIntervalLabel'>How often would you like to receive digest emails of recent activity?</FormHelperText>
                    <SelectField
                        value={digestInterval}
                        labelId='digestIntervalLabel'
                        onChange={this.setSelectValue('digestInterval')}
                        options={[
                            { value: 'daily', label: 'Daily' },
                            { value: 'weekly', label: 'Weekly' },
                            { value: 'none', label: 'Never' }
                        ]}
                    />
                </section> : null}
                {fieldsToShow.includes('pendingConnections') ? <section>
                    <FormHelperText id='pendingConnectionsLabel'>Would you like to receive email reminders about connection requests?</FormHelperText>
                    <SelectField
                        value={pendingConnections}
                        labelId='pendingConnectionsLabel'
                        onChange={this.setSelectValue('pendingConnections')}
                        options={[
                            { value: 'on', label: 'Yes' },
                            { value: 'off', label: 'No' }
                        ]}
                    />
                </section> : null}
                {!hideJobAlerts && headersToShow.includes('other') ? <>
                    {noDivider ? null : <Divider className={Classes.divider} />}
                    <h3>Other</h3>
                </> : null}
                {!hideJobAlerts && fieldsToShow.includes('careerAlerts') ? <section>
                    <FormHelperText id='careerAlertsLabel'>Are you interested in alerts about internships, jobs, and related opportunities based on your profile?</FormHelperText>
                    <SelectField
                        value={careerAlerts}
                        labelId='careerAlertsLabel'
                        onChange={this.setSelectValue('careerAlerts')}
                        options={[
                            { value: 'on', label: 'Yes' },
                            { value: 'off', label: 'No' }
                        ]}
                    />
                </section> : null}
                {/*HIDE INTEREST POP UP QUESTION*/}
                {/*{noDivider ? null : <Divider className={Classes.divider} />}
                <h3>Interests</h3>
                <section>
                    <RadioButtonGroup
                        className={Classes.radioGroup}
                        name='hideInterestPopup'
                        valueSelected={hideInterestPopup}
                        onChange={this.setFieldValue('hideInterestPopup', true)}
                    >
                        <RadioButton value='true' label='Yes' aria-label='Yes' />
                        <RadioButton value='false' label='No' aria-label='No' />
                    </RadioButtonGroup>
                </section>*/}
            </div>
        );
    }
};
